import React from 'react';
import { Helmet } from 'react-helmet';
import '../../css/Header.css'

function Desarrollo(props) {
  props.setBackgroundImage('/img/AdobeStock_275870548.jpeg')

  return (
    <div>
      <Helmet>
        <title>Enginios - Desarrollo de aplicaciones</title>
        <meta name="description" content= "Enginios crea aplicaciones tanto para particulares como para empresas. Páginas web, programas para PC, bases de datos, realidad virtual, ¡Y mucho más!"/>
        <meta name="keywords" content="Enginios desarrollo, Enginios paginas web, Enginios programacion"/>
        <meta name="robots" content= "index, follow"/>
      </Helmet>
      <div className="contHeader">
        <header>
            <h1>Desarrollo de aplicaciones</h1><br/>
        </header>
      </div>
      <div className="mainBlank">
        <main>
          <h2>Tanto si eres particular o empresa, tenemos la solución para ti</h2>
          <p>Conocemos diversos lenguajes de programación y nos manejamos con soltura en la programación y el desarrollo de aplicaciones.</p>
          <p>Nos adaptamos al tamaño y al presupuesto de nuestro cliente para ofrecerle la herramienta que mejor se adapte a sus necesidades, tanto si son pequeñas páginas web para pequeños comercios como en proyectos grandes y bases de datos de gran volumen para gestión de grandes empresas.</p>
          <h3>Especialistas en tecnologías web</h3>
          <p>Nuestra actividad y experiencia nos ha llevado a manejar varias tecnologías de programación. Pero tanto el entorno como nuestra inquietud personal, nos ha llevado a especializarnos en tecnologías web, ya que la actualidad está evolucionando a que gran parte de la actividad económica y de gestión se haga online. Internet ha venido para revolucionar el mundo y ha venido para quedarse, por lo que Enginios te puede ayudar a sacar el máximo partido.</p>
          <p>En la actualidad, existen numerosas formas de elaborar una aplicación web, de hecho, hay tantas que puede llegar a resultar confuso saber cual es la que se adapta mejor a tus necesidades. A continuación, te explicamos las opciones que tienes disponibles en Enginios para tu aplicación web.</p>
          <h2>Opciones para tu aplicación web</h2>
          <h3>Programación</h3>
          <p>Las aplicaciones web, al igual que cualquier programa de pc, llevan una programación por detrás. A día de hoy, muchas herramientas facilitan la creación de páginas web sin necesidad de saber programación, pero en Enginios dominamos la programación web, para poder frecerte máxima flexibilidad y personalización de tu aplicación web.</p>
          <p>Dentro de la programación web, también existen numerosas disciplinas de trabajo tanto de "frontend" (cliente) como de "backend" (servidor)</p>
          <h4>Tecnologías frontend (cliente)</h4>
          <div class="grid_21">
              <div>
                  <p>  Programación "pura": Html, CSS y Javascript</p>
                  <p>  React: Popular framework que facilita la programación front descargando el trabajo de servidor.</p>
              </div>
              <div><img src="/img/html_css_js.png" alt=""/></div>
              
          </div>
          <h4>Tecnologías backend (servidor)</h4>
          <p>· PHP: Lenguaje de servidor más extendido, ya que una de las mayores plataformas de elaboración de webs está hecha con PHP.</p>
          <p>· Node.js: Servidor en alza, ya que usa Javascript, el mismo lenguaje que el frontend facilitando mucho trabajo y es una herramienta potente.</p>
          <h3>Elaboración de páginas web con Wordpress</h3>
          <p>Wordpress se habrá convertido en la herramienta más popular para confeccionar páginas web del mundo. A pesar de ser un método de confección que resta algo de flexibilidad al desarrollo, Wordpress es el indiscutible ganador por una máxima del siglo 21: La productividad. Con Wordpress somos capaces de elaborar páginas web con sencillas con bastante presteza.</p>
          <p>Es por ello que tenemos experiencia en la elaboración de páginas web con Wordpress, el cual dispone de herramientas interesantes de gestión para el usuario y la posibilidad de incluir buenas funcionalidades para el usuario. Sistemas de reservas, tienda online, etc.</p>
          <h3>Servicios de terceros</h3>
          <p>Además de la programación y de Wordpress, existen numerosos servicios de terceros que permiten la elaboración de páginas web con sus herramientas propias. Como las páginas web se ha convertido en un producto masificado, las páginas tienden a tener estructuras parecidas, lo cual ha dado pie a estos servicios de terceros con editores de páginas web sencillos para elaborar páginas web sencillas. Suelen ser sistemas poco flexibles, pero sencillos de manejar para sacar páginas web sencillas</p>
          <p>Te podríamos ayudar con estos sistemas de terceros, pero a nuestro entender son los menos aconsejables. Contacta con nostros para más información.</p>
          <h2>comparación de sistemas de desarrollo de aplicaciones web</h2>
          <div class="webComparison">
              <div></div>
              <p>Inversión inicial</p>
              <p>Gasto mensual</p>
              <p>Flexibilidad</p>
              <div class="disp"></div>
              <p class="disp">Inversión inicial</p>
              <p class="disp">Gasto mensual</p>
              <p class="disp">Flexibilidad</p>
              <div class="disp"></div>
              <p class="disp">Inversión inicial</p>
              <p class="disp">Gasto mensual</p>
              <p class="disp">Flexibilidad</p>
              <div class="cell backY"><p>Programación pura</p></div>
              <div class="cell backY"><div class="barCont"><div class="barFill" id="fill1"></div></div></div>
              <div class="cell backY"><div class="barCont"><div class="barFill" id="fill2"></div></div></div>
              <div class="cell backY"><div class="barCont"><div class="barFill" id="fill3"></div></div></div>
              <div class="cell"><p>Wordpress</p></div>
              <div class="cell"><div class="barCont"><div class="barFill" id="fill4"></div></div></div>
              <div class="cell"><div class="barCont"><div class="barFill" id="fill5"></div></div></div>
              <div class="cell"><div class="barCont"><div class="barFill" id="fill6"></div></div></div>
              <div class="cell backY"><p>Terceros</p></div>
              <div class="cell backY"><div class="barCont"><div class="barFill" id="fill7"></div></div></div>
              <div class="cell backY"><div class="barCont"><div class="barFill" id="fill8"></div></div></div>
              <div class="cell backY"><div class="barCont"><div class="barFill" id="fill9"></div></div></div>
          </div>
          <h3>Contacta con nosotros para más información</h3>
        </main>
      </div>
    </div>
    
  );
};

export default Desarrollo