import React, { startTransition } from 'react';
import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import '../css/Nav.css'
import ToggleLink from './ToggleLink';

function Nav({navBackground}) {
  
  const [displayMenu1, setDisplayMenu1] = useState();

  const toggleDisplayMenu1 = () => {
    if(displayMenu1==true){
      setDisplayMenu1(false);
    }
    else{
      setDisplayMenu1(true)
    }
  };

  const [displayMenu2, setDisplayMenu2] = useState();

  const toggleDisplayMenu2 = () => {
    if(displayMenu2==true){
      setDisplayMenu2(false);
    }
    else{
      setDisplayMenu2(true)
    }
  };
  
  return (
    <div className="contNav" style={{backgroundColor: `${navBackground}`}}>
      <nav>
        <a href="#" className="burger" onClick={toggleDisplayMenu1}>
          <img src="img/burger.svg" alt=""/>
        </a>
        <Link to="/" className='contAvatar'>
            <img src={require("../assets/img/ineris simbolo alpha.png")} alt="" className="avatar"/>
        </Link>
        <ul className="menu1" style={{ display: displayMenu1 ? 'flex' : '' }} id="m1">
            
            <li><ToggleLink to="/" handleClick={toggleDisplayMenu1}>Inicio</ToggleLink></li>
            <li>
              <ToggleLink to="/servicios" handleClick={toggleDisplayMenu1}>Servicios</ToggleLink>
              <img className="menuButton" src="img/arrow_forward.svg" alt="" style={{height: '40px', rotate: displayMenu2 ? '90deg' : '0deg' }} onClick={toggleDisplayMenu2}/>
              <ul className="menu2" style={{ display: displayMenu2 ? 'flex' : '' }} id="m2">
                <li onClick={toggleDisplayMenu1}><Link to="/servicios/diseno">Diseño gráfico</Link></li>
                <li onClick={toggleDisplayMenu1}><Link to="/servicios/desarrollo">Desarrollo</Link></li>
                <li onClick={toggleDisplayMenu1}><Link to="/servicios/sistemas">Sistemas IT</Link></li>
                <li onClick={toggleDisplayMenu1}><Link to="/servicios/ingenieria">Ingeniería</Link></li>
              </ul>
            </li>
            <li><ToggleLink to="/casosdeexito" handleClick={toggleDisplayMenu1}>Casos de éxito</ToggleLink></li>
            <li><ToggleLink to="/nuestroequipo" handleClick={toggleDisplayMenu1}>Nuestro equipo</ToggleLink></li>
            <li><ToggleLink to="/contacto" handleClick={toggleDisplayMenu1}>Contacto</ToggleLink></li>
        </ul>
      </nav>
    </div>
  );
};

export default Nav