import React from 'react';
import { Helmet } from 'react-helmet';
import '../css/Header.css'
/* import axios from 'axios';
import { useState, useEffect } from 'react'; */

function Inicio(props) {
  props.setBackgroundImage('/img/zoom-office-background.jpg')

  

  return (
    <div>
      <Helmet>
        <title>Enginios - Nuestro equipo</title>
        <meta name="description" content= "En Enginios somos y nos rodeamos de los mejores profesionales para darte el mejor servicio. Conócenos."/>
        <meta name="keywords" content="Enginios equipo, Enginios personas, Enginios empleados"/>
        <meta name="robots" content= "index, follow"/>
      </Helmet>
      <div className="contHeader">
        <header>
            <h1>Nuestro equipo</h1><br/>
        </header>
      </div>
      <div className="mainBlank">
        <main>
            <h2>Personas al servicio de personas</h2>
            <p>Detrás del proyecto Enginios hay personas que, como tú, luchamos por la profesionalidad, la calidad de nuestros servicios y la satisfacción de nuestros clientes</p>
            <h3>Pablo Merino</h3>
            <p>CEO de Enginios. Ingeniero industrial. Actualmente trabajando como jefe de proyecto en CAF, una de las mayores empresas fabricantes de trenes del mundo. Ha trabajado en múltiples sectores y desarrolló su propia empresa, Virtoria, Centro de realidad Virtual. Después de cerrar por la grave crisis del coronavirus, se encuentra trabajando de forma estable y ha levantado el proyecto Enginios como proyecto paralelo para ofrecer toda su experiencia en diseño, programación y sistemas de redes.</p>
            <h3>Luis Merino</h3>
            <p>Segundo miembro de la familia Enginios y la familia Merino. Hermano de Pablo, ingeniero industrial también y Lead Developer de Enginios. Después de trabajar como ingeniero muchos años, consiguió cambiar de trayectoria estudiando un master de desarrolo de realidad virtual y en la actualidad se encuentra trabajando en Simumak, una empresa desarrolladora de simuladores virtuales. Colabora activamente en los proyectos de Enginios ofreciendo todos sus conocimientos en programación de videojuegos y realidad virtual.</p>
        </main>
      </div>
    </div>
    
  );
};

export default Inicio