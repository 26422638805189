import React from 'react';
import { Helmet } from 'react-helmet';
import '../../css/Header.css'

function Ingenieria(props) {
  props.setBackgroundImage('/img/443994-engineering-drawing-architecture.jpg')

  return (
    <div>
      <Helmet>
        <title>Ineris - Ingeniería</title>
        <meta name="description" content= "Somos ingenieros y ponemos a tu disposición nuestra experiencia para tus proyectos industriales, domésticos, reformas o mantenimiento. ¡Consultanos!"/>
        <meta name="keywords" content="ineris ingenieria, ineris reformas"/>
        <meta name="robots" content= "index, follow"/>
      </Helmet>
      <div className="contHeader">
        <header>
            <h1>Ingeniería</h1><br/>
        </header>
      </div>
      <div className="mainBlank">
        <main>
          <h2>Tu proyecto en buenas manos</h2>
          <p>Como ingenieros, ponemos a tu disposición nuestra experiencia en diseño industrial, reformas y mantenimiento de edificios.</p>
          <h2>MÁS INFORMACIÓN PRÓXIMAMENTE</h2>
        </main>
      </div>
    </div>
    
  );
};

export default Ingenieria