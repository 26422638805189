import React from 'react';
import '../css/aside.css'
import { useState, useEffect } from 'react';
import axios from 'axios';
import Card from './card';

function Aside() {

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    axios.post('https://www.enginios.eu/giveNews')
      .then(response => {
        setData(response.data.resp.reverse());
        console.log(response.data)
        setLoading(false);
      })
      .catch(error => {
        console.error(error);
        setLoading(false);
      });
  }, []);
  /*
  useEffect(() => {
    
    axios.post('http://localhost:11011/giveNews')
      .then(response => {
        setData(response.data.resp.reverse());
        console.log(response.data)
      })
      .catch(error => {
        console.log(error);
      });
  }, []);
  */
  const [sliderPosition, setSliderPosition] = useState(0);
  function toNext() {
    if(sliderPosition > -(270*5)) {
      setSliderPosition(sliderPosition - 270)
      console.log(sliderPosition)
    } 
  }
  function toPrev() {
    if(sliderPosition < 0) {
      setSliderPosition(sliderPosition + 270)
      console.log(sliderPosition)
    }
  }

  return (
    <div className="mainBlank">
      <aside>
          <h2>Últimas noticias</h2>
          <div className="sliderContainer"> 
            <div id="sliderPages" style={{left: `${sliderPosition}px`}}>
              {loading && <div><img src={require("../assets/img/ajax-loader-gif-6.gif")} alt=""/></div>}
              {!loading && data && data.map((cardItem) => (
                <Card
                  date={cardItem.date}
                  image_link={cardItem.image_link}
                  title={cardItem.title}
                  content={cardItem.content}
                />
              ))}
              {data.resp && data.resp[0].id}
            </div>
          </div>
          <div className="slideButtons">
              <img className="sliderArrow" src="img/arrow_forward.svg" style={{rotate: '180deg'}}alt="" onClick={toPrev}/>
              <img className="sliderArrow" src="img/arrow_forward.svg" alt="" onClick={toNext}/>
          </div>   
          
          <br/>
          <a className="link" href="">Ver más</a>
      </aside>
    </div>
  );
};

export default Aside