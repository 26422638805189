import React from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import '../css/Header.css';

function Servicios(props) {
  props.setBackgroundImage('/img/AdobeStock_275870548.jpeg')

  return (
    <div>
      <Helmet>
        <title>Enginios - Nuestros servicios</title>
        <meta name="description" content= "Descubre todo en lo que te puede ayudar Enginios. Diseño, desarrollo web, sistemas informáticos ¡y mucho más!"/>
        <meta name="keywords" content="Enginios servicios, Enginios productos"/>
        <meta name="robots" content= "index, follow"/>
      </Helmet>
      <div className="contHeader">
        <header>
            <h1>Servicios</h1><br/>
        </header>
      </div>
      <div className="mainBlank">
        <main>
          <h2>Herramientas digitales para tu empresa</h2>
          <p>Cualquier empresa sin presencia en internet y sin las herramientas de gestión adecuadas, puede resultar en consecuencias graves de competitividad, productividad y posicionamiento en el mercado.</p>
          <p>Enginios ofrece una gestión integral de herramientas informáticas y digitales para el lanzamiento, promoción, gestión y crecimiento de tu empresa, para que tu empresa se dedique a lo realmente importante, a desarrollar y vender su producto.</p>
          <h3>EXPLORAR ENGINIOS</h3>
          <Link className="link" to="/servicios/diseno">Diseño de marca e imágen</Link>
          <Link className="link" to="/servicios/desarrollo">Desarrollo y programación</Link>
          <Link className="link" to="/servicios/sistemas">Sistemas y redes informáticas</Link>
          <Link className="link" to="/servicios/ingenieria">Ingeniería</Link>
        </main>
      </div>
    </div>
    
  );
};

export default Servicios