import React from 'react';
import { Helmet } from 'react-helmet';
import '../../css/Header.css'

function Sistemas(props) {
  props.setBackgroundImage('/img/AdobeStock_461884798.jpeg')

  return (
    <div>
      <Helmet>
        <title>Ineris - Sistemas IT</title>
        <meta name="description" content= "Monta tu propia red informática con Ineris para no depender de las grandes tecnológicas. Sistemas de nube, alojamiento web, seguridad... ¡Y mucho más!"/>
        <meta name="keywords" content="ineris redes, ineris sistemas, ineris seguridad"/>
        <meta name="robots" content= "index, follow"/>
      </Helmet>
      <div className="contHeader">
        <header>
            <h1>Sistemas IT</h1><br/>
        </header>
      </div>
      <div className="mainBlank">
        <main>
          <h2>Toda la infraestructura informática y red de comunicaciones que necesitas para tu empresa</h2>
          <p>Ineris, en su afán de autogestionar su infraestructura de red informática sin tener que depender de costosas cuotas mensuales a las grandes tecnológicas, ha aprendido a gestionar de forma propia, flexible y económica unas herramientas que nos han sido de gran utilidad para el despliegue de nuestros servicios.</p>
          <h3>Ahora, ponemos toda esa experiencia a tu disposición</h3>
          <p>Ya sea en servidores propios de Ineris o en un servidor que podemos montar en tu ubicación física, disfruta de:</p>
          <ol>
              <li>Alojamiento para tu página web</li>
              <li>Servicio de nube</li>
              <li>Acceso remoto a tus carpetas en red</li>
          </ol>
          <h2>Cómo lo hacemos</h2>
          <p>Hacemos uso de unos servidores llamados NAS, que son como una versión reducida de ordenadores, cuyo objetivo no es procesar grandes cálculos ni entrornos gráficos, sino, como su nombre indica, servir contenidos, ya sea en formato de página web, carpetas de archivos, correo electrónico, etc.</p>
        </main>
      </div>
    </div>
    
  );
};

export default Sistemas