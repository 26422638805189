import React from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import Aside from '../comp/Aside';
import '../css/Header.css'
/* import axios from 'axios';
import { useState, useEffect } from 'react'; */

function Inicio(props) {
  props.setBackgroundImage('/img/AdobeStock_234447034.jpeg')

  return (
    <div>
      <Helmet>
        <title>Enginios, servicios tecnológicos</title>
        <meta name="description" content= "Enginios es el nuevo estudio de diseño, desarrollo y sistemas informáticos en San Sebastián. Entra para informarte sobre todos nuestros sevicios"/>
        <meta name="keywords" content="Enginios, desarrollo san sebastian, programacion donostia, programacion san sebastian, paginas web san sebastián, sistemas san sebastián, sistemas Donostia, diseño, diseño gráfico"/>
        <meta name="robots" content= "index, follow"/>
      </Helmet>
      <div className="contHeader">
        <header>
            <img src={require("../assets/img/Enginios logo.png")} alt=""/>
            <h1>Servicios tecnológicos</h1><br/>
        </header>
      </div>
      <div className="mainBlank">
        <main>
            <h2>Bienvenido a Enginios</h2>
            <p>Somos un pequeño estudio de ingenieros dispuestos a poner a tu disposición toda nuestra experiencia tecnológica para sacar adelante tu proyecto mediante soluciones integrales o mediante servicios específicos.</p>
            <h3>¿Qué necesitas?</h3>
            <p>Tanto si eres particular o empresa, podemos ayudarte.</p>
            <div className="serviceList">
                <div className="service">
                    <h2>Diseño gráfico</h2>
                    <ul>
                        <li>Creación de tu logo personal o de empresa</li>
                        <li>Diseño de todo tipo de material gráfico: Tarjetas, flyers o carteles...</li>
                        <li>Renders 3D</li>
                        <li>Diseño de escenas</li>
                        <br/>
                        <li><Link className="link" to="/servicios/diseno">Ver más</Link></li>
                    </ul>
                </div>
                <div className="service">
                    <h2>Desarrollo y programación</h2>
                    <ul>
                        <li>Páginas web</li>
                        <li>Blogs</li>
                        <li>Aplicaciones web corporativas</li>
                        <li>Aplicaciones de realidad virtual (Próximamente)</li>
                        <li>Tienda on-line</li>
                        <li>Bases de datos</li>
                        <br/>
                        <li><Link className="link" to="/servicios/desarrollo">Ver más</Link></li>
                    </ul>
                </div>
                <div className="service">
                    <h2>Sistemas informáticos</h2>
                    <ul>
                        <li>Instalación y puesta en marcha de servidores para particulares o empresas</li>
                        <li>Alojamiento en servidores de Enginios o propios</li>
                        <li>Acceso en remoto y teletrabajo</li>
                        <li>Servidores web, correo electrónico y bases de datos</li>
                        <br/>
                        <li><Link className="link" to="/servicios/sistemas">Ver más</Link></li>
                    </ul>
                </div>
                <div className="service">
                    <h2>Ingeniería</h2>
                    <ul>
                        <li>Proyección y delineación de planos</li>
                        <li>Obras y mantenimiento de viviendas</li>
                        <li>Ingeniería de producción</li>
                        <li>Diseño industrial</li>
                        <li></li>
                        <br/>
                        <li><Link className="link" to="/servicios/Ingenieria">Ver más</Link></li>
                    </ul>
                </div>
            </div>
        </main>
      </div>
      <Aside/>
    </div>
    
  );
};

export default Inicio