import React from "react";

function Card(props) {
    return(
        <div className="card">
            <p>{props.date.slice(0,10)}</p>
            <div>
                <img
                    src={require(`../assets${props.image_link}`)}
                    alt=""
                />
            </div>
            <h3>{props.title}</h3>
            <p>{props.content}</p>
        </div>
        
    );
}
export default Card;