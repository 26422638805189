import React from 'react';
import { Helmet } from 'react-helmet';
import { useState, useEffect } from 'react';
import '../../css/Header.css';
import '../../css/popupSlider.css';

function Diseno(props) {
  props.setBackgroundImage('/img/minimalism-1647265934500-7619.jpg')

  const [displaySlider, setDisplaySlider] = useState("none");
  function toggleSlider() {
    if(displaySlider == "none") {setDisplaySlider("block")}
    else {setDisplaySlider("none")}
  }

  const [sliderPosition, setSliderPosition] = useState(0);
  function toNext() {
    if(sliderPosition > -((window.innerWidth - 40)*6)) {
      setSliderPosition(sliderPosition - (window.innerWidth - 40))
      console.log(sliderPosition)
    } 
  }
  function toPrev() {
    if(sliderPosition < 0) {
      setSliderPosition(sliderPosition + (window.innerWidth - 40))
      console.log(sliderPosition)
    }
  }

  return (
    <div>
      <Helmet>
        <title>Ineris - Diseño gráfico</title>
        <meta name="description" content= "En Ineris creamos arte gráfico digital para tu marca, producto o imagen. ¡Descúbrelo!"/>
        <meta name="keywords" content="ineris diseño gráfico, ineris arte, ineris marca"/>
        <meta name="robots" content= "index, follow"/>
      </Helmet>
      <div className="contHeader">
        <header>
            <h1>Diseño gráfico</h1><br/>
        </header>
      </div>
      <div className="mainBlank">
        <main style={{position: "relative"}}>
          <h2>Además de técnicos, somos diseñadores</h2>
          <p>En nuestra trayectoria profecsional, hemos aprendido a hacer nuestro trabajo, además de técnicamente competente, con gusto, cariño y de froma que sea agradable a la vista (Lo que en el mundo digital y web se denomina "User Friendly").</p>
          <p>Bajo esta premisa, Enginios ha adquirido experiencia en diseño gráfico, tanto 2D y 3D, para el desarrollo de marca e imagen para la empresa.</p>
          <h2>No es lo que sabes, sino lo que puedas demostrar</h2>
          <p>Diseñamos tarjetas con clase, flyers con gancho, carteles que llamen la atención o cualquier recurso gráfico que le haga parecer a tu empresa seria, profesional y con el aire que se le quiera dar.</p>
          <h2>Todo el arte de esta página es propio</h2>
          <p>Toda la experiencia adquirida para proyectos propios y ajenos, la ponemos a tu disposición para diseñar e implementar una imagen para tu proyecto.</p>
          <h2>Echa un vistazo a nuestra galería. Si te gusta lo que ves, ¡Contacta con nosotros!</h2>
          <button onClick={toggleSlider}>Abrir galería</button>
          
          <br/>
        </main>
      </div>
      <div className="popupSlider" style={{display: `${displaySlider}`}}> 
            <div id="popupSliderPages" style={{left: `${sliderPosition}px`}}>
              <div className='sliderPage'>
                <img src={require("../../assets/img/diseno/Logo_sustrai_nutricion.png")}></img>
              </div>
              <div className='sliderPage'>
                <img src={require("../../assets/img/diseno/Avatar nuevo.png")}></img>
              </div>
              <div className='sliderPage'>
                <img src={require("../../assets/img/diseno/Avatar_sustrai_fresita_3.png")}></img>
              </div>
              <div className='sliderPage'>
                <img src={require("../../assets/img/diseno/Portada general logo negro.png")}></img>
              </div>
              <div className='sliderPage'>
                <img src={require("../../assets/img/diseno/simbolo virtoria 2K alpha.png")}></img>
              </div>
              <div className='sliderPage'>
                <img src={require("../../assets/img/diseno/Vittori solid pose.PNG")}></img>
              </div>
              <div className='sliderPage'>
                <img src={require("../../assets/img/diseno/Vittori evee alpha.png")}></img>
              </div>
              
            </div>
            <div className="sliderClose" onClick={toggleSlider}>X</div>
            <div className="slideButtons">
              <img className="sliderArrow" src="../img/arrow_forward.svg" style={{rotate: '180deg'}} alt="" onClick={toPrev}/>
              <img className="sliderArrow" src="../img/arrow_forward.svg" alt="" onClick={toNext}/>
            </div>
          </div>
    </div>
    
  );
};

export default Diseno