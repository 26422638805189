import React from 'react';
import { Helmet } from 'react-helmet';
import '../css/Header.css'
/* import axios from 'axios';
import { useState, useEffect } from 'react'; */

function Casosdeexito(props) {
  props.setBackgroundImage('/img/bigstock-chart-background-203062333.jpg')

  

  return (
    <div>
      <Helmet>
        <title>Enginios - Casos de éxito</title>
        <meta name="description" content= "Enginios ha trabajado en muchos proyectos, todos ellos desarrollados, con éxito, profesionalidad y gusto. Descúbrelos."/>
        <meta name="keywords" content="Enginios proyectos, Enginios casos de exito"/>
        <meta name="robots" content= "index, follow"/>
      </Helmet>
      <div className="contHeader">
        <header>
            <h1>Casos de éxito</h1><br/>
        </header>
      </div>
      <div className="mainBlank">
        <main>
          <p>Estos son algunos de los proyectos llevados a cabo por Enginios con éxito</p>
          <div class="proyTable"></div>
        </main>
      </div>
    </div>
    
  );
};

export default Casosdeexito