import logo from './logo.svg';
import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { useState, useEffect } from 'react';
import Inicio from './pages/Inicio';
import Servicios from './pages/Servicios';
  import Diseno from './pages/servicios/Diseno';
  import Desarrollo from './pages/servicios/Desarrollo';
  import Ingenieria from './pages/servicios/Ingenieria';
  import Sistemas from './pages/servicios/Sistemas';
import Casosdeexito from './pages/Casosdeexito';
import Nuestroequipo from './pages/Nuestroequipo';
import Contacto from './pages/Contacto';
import './css/Nav.css';
import Nav from './comp/Nav';

function App() {
  const [backgroundImage, setBackgroundImage] = useState('');
  const [navBackground, setNavBackground] = useState('');

  const handleScroll = () => {
    const scrollPosition = document.getElementById('App').scrollTop;
    if (scrollPosition > 0) {
      setNavBackground('white');
    } else {
      setNavBackground('');
    }
  };
  
  return (
    <div className='App' id="App" style={{ backgroundImage: `url(${backgroundImage})`}} onScroll={handleScroll}>
      <Router>
        <Nav navBackground={navBackground}/>
        <Routes>
          <Route path="/" element={<Inicio setBackgroundImage={setBackgroundImage}/>}/>
          <Route path="/servicios" element={<Servicios setBackgroundImage={setBackgroundImage}/>}/>
            <Route path="/servicios/diseno" element={<Diseno setBackgroundImage={setBackgroundImage}/>}/>
            <Route path="/servicios/desarrollo" element={<Desarrollo setBackgroundImage={setBackgroundImage}/>}/>
            <Route path="/servicios/sistemas" element={<Sistemas setBackgroundImage={setBackgroundImage}/>}/>
            <Route path="/servicios/ingenieria" element={<Ingenieria setBackgroundImage={setBackgroundImage}/>}/>
          <Route path="/casosdeexito" element={<Casosdeexito setBackgroundImage={setBackgroundImage}/>}/>
          <Route path="/nuestroequipo" element={<Nuestroequipo setBackgroundImage={setBackgroundImage}/>}/>
          <Route path="/contacto" element={<Contacto setBackgroundImage={setBackgroundImage}/>}/>
        </Routes>
      </Router>
      
    </div>
  );
}

export default App;